import React from "react";

import { LanguageProvider } from "../components/languageProvider";
import { HomePage } from "../components/homePage";

const IndexPage = () => {
  return (
    <LanguageProvider language="pl">
      <HomePage />
    </LanguageProvider>
  );
};

export default IndexPage;
